<template>
    <div class="index-page">
        <!-- <div v-loading="loading" class="loading"></div> -->


        <!-- <button @click="startToGetCode(1)">开始正常流程进入数字教材</button> <br>
        <button @click="startToGetCode(2)">开始正常流程进入数字资源</button> -->
    </div>
</template>

<script>
import {
    Get_Usertoken_By_Session
} from '@/http/api.js';
export default {
    name: 'Page',
    data() {
        return {
            loading: true, // loading
            authUrl: '',
            jumpUrl: '',
            appid: '1823981868110970882',

            // 数字教材：
            uri: 'http://121.36.71.62:8886/api/auth/api/v1/baseCamp/necipub/loginByCode?redirectUrl=http%3A%2F%2F121.36.71.62%3A8886%2Fzjyzwebmain%2F%23%2FthreeLogin%3Ffrom%3Dchuangerxin%26router%3D%2Fteaching%2Fmy-teaching-material',
            // uri: 'http%3A%2F%2F121.36.71.62%3A8886%2Fapi%2Fauth%2Fapi%2Fv1%2FbaseCamp%2Fnecipub%2FloginByCode%3FredirectUrl=http%253A%252F%252F121.36.71.62%253A8886%252Fzjyzwebmain%252F%2523%252FthreeLogin%253Ffrom%253Dchuangerxin%2526router%253D%252Fteaching%252Fmy-teaching-material',

            // 数字资源：
            // uri2: 'http%3A%2F%2F121.36.71.62%3A8886%2Fapi%2Fauth%2Fapi%2Fv1%2FbaseCamp%2Fnecipub%2FloginByCode%3FredirectUrl=http%253A%252F%252F121.36.71.62%253A8886%252Fzjyzwebmain%252F%2523%252FthreeLogin%253Ffrom%253Dchuangerxin%2526router%253D%252Fteaching%252Fresource-library',
            uri2: 'http://121.36.71.62:8886/api/auth/api/v1/baseCamp/necipub/loginByCode?redirectUrl=http%3A%2F%2F121.36.71.62%3A8886%2Fzjyzwebmain%2F%23%2FthreeLogin%3Ffrom%3Dchuangerxin%26router%3D%2Fteaching%2Fresource-library',
        }
    },

    async created() {
        let { code, type, session, isDebug } = this.$route.query;
        let appid = this.$route.query.appid || this.appid; // appid可以通过链接参数但也有默认值
        let env = this.getEnv();
        this.authUrl = `${env === 'test' ? 'https://testbasecamp' : 'http://basecamp'}.necibook.com/api/auth/api/v1/oauth/authorize`;
        if (session) {
            let localHref = encodeURIComponent(location.origin + location.pathname); // 需要重定向的地址【需要去除地址上的带的参数】
            let jumpUrl = this.authUrl + `?redirect_uri=${localHref}&redirect_flag=true&client_id=${appid}&response_type=code`;

            localStorage.setItem('necibookResourcesType', type || '1'); // 存储当前资源类型

            try {
                let res = await Get_Usertoken_By_Session({ session });

                console.log('获取到的用户信息：', res);
                let data = res.data || {};
                if (data.ReturnCode == 1) {
                    let result = data.result || [];
                    let user = result[0] || {};
                    let curr = user.current_Identity || {};

                    jumpUrl = jumpUrl + `&userId=${user.user_id}&identityRoleId=${curr.identity_role_id || ''}`;
                }
            } catch (error) {
                console.log('接口报错了', error);
            }

            if (isDebug === 'true') {
                // 设置调试模式，这里不进行跳转
                console.log('当前跳转的地址为：', jumpUrl);
            } else {
                location.href = jumpUrl;
            }
        } else if (code) {
            // 若地址中不存在type参数，则从本地存储中获取
            if (!type) type = localStorage.getItem('necibookResourcesType');
            // 1-数字教材：  2-数字资源：
            location.href = `${type == 1 ? this.uri : this.uri2}&code=${this.$route.query.code}`;
        }
    },
    methods: {
        startToGetCode(type) {
            const redirectUri = `${location.origin}/Necibook/Transit%3Ftype%3D${type}`;
            const state = this.$route.query.state || ''; // win-windows端【默认】 | android-安卓端
            const appid = this.$route.query.appid || this.appid; // appid可以通过链接参数但也有默认值
            localStorage.setItem('necibookState', state);
            window.location.href = `${this.authUrl}?response_type=code&client_id=${appid}&redirect_uri=${redirectUri}&redirect_flag=true&state=${state}`;
        }
    }
}
</script>

<style lang="scss" scoped>
.index-page {
    width: 100vw;
    height: 100vh;

    .loading {
        width: 100%;
        height: 100%;
    }
}
</style>