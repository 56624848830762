<template>
    <div class="index-page">
        <!-- <div v-loading="loading" class="loading"></div> -->
    </div>
</template>

<script>
import {
    NECIBOOK_GET_USERCENTER
} from '@/http/api.js';
export default {
    name: 'Auth',
    data() {
        return {
            loading: true, // loading
        }
    },
    created() {
        const code = this.$route.query.code;
        // 通过返回的code来获取session


        this.getSessionByCode(code);
    },
    methods: {
        async getSessionByCode(code) {
            const state = localStorage.getItem('necibookState') || 'win';
            try {
                const res = await NECIBOOK_GET_USERCENTER({ code });
                let data = res.data || {};
                

                if(typeof data === 'string'){
                    data = JSON.parse(data);
                }

                console.log('返回的参数：', data);

                let session = '';
                let error = ''; // 是一个字符串错误提示内容；
                let result = 0; // 0 成功， 1， 失败;
                if (data.ReturnCode == 1) {
                    /**
                     * 反馈客户端：
                     * result : 0 成功， 1， 失败;
                     * error: 是一个字符串错误提示内容；
                     * */
                    session = data.result[0];
                    error = data.ReturnText || '';
                } else {
                    result = 1;
                    session = '';
                    error = data.ReturnText || '登录失败';
                }

                if (window.CallCPP || state == 'win') {
                    if(window.CallCPP) window.CallCPP('<WebLogin result="' + result + '" session="' + session + '" error="' + error + '"></WebLogin>');
                } else if(window.HostApp || state == 'android'){
                    if(window.HostApp) window.HostApp.alert({ "cmd": "Sessionlogin", "session": session });
                }
            } catch (error) {
                console.log('发生错误：', error);
                // 报错了，重新跳转回登录页面
                this.$router.push(`/Necibook/SignIn?state=${state}`);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.index-page {
    width: 100vw;
    height: 100vh;

    .loading {
        width: 100%;
        height: 100%;
    }
}
</style>